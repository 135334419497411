{
  "project": [
    {
      "id": 13,
      "keyword": "# Project",
      "title": "LG전자 사내벤처 그로로 레거시 페이지 리뉴얼 및 기능별 코드 통폐합",
      "date": "2차 재계약 (풀타임) 2023.08.31 ~ 2024.02.29\n3차 재계약 (풀타임) 2024.02.29 ~ 2025.02.28",
      "summary": [
        "React",
        "Recoil",
        "React-query",
        "Next",
        "반응형 웹",
        "퍼블리싱",
        "기획 일부 참여",
        "재택근무"
      ],
      "text": "기존의 그로로 서비스는 Chakra UI라는 프레임워크에 종속적이며 필요에 따라 부분적으로 오버라이드를 하여 사용하고 있었습니다. 코드가 복잡함은 물론 node 버전도 올릴 수가 없는 상태라서 오래된 화면이나 라이브러리부터 하나씩 수정하는 작업을 진행했습니다. 프레임워크를 사용한 페이지는 기존과 동일하게 퍼블하거나 새 디자인 요청을 적용해드렸고, 간단한 라이브러리들은 직접 util 파일이나 훅, 컴포넌트들로 개발하여 대체하였고 화면마다 구성된 동일기능은 별도의 컴포넌트로 통폐합하였습니다. 또한 기간 내 다양한 이벤트 페이지 개발과 커뮤니티 서비스에 답글 및 멘션 기능 등을 추가로 개발, 처음 개발했던 개별 서비스 메이커를 기존 서버 내부로 포팅, 그 외에 거의 모든 화면들에 새로운 디자인과 기획을 받아 리뉴얼하였습니다."
    },
    {
      "id": 12,
      "keyword": "# Project",
      "title": "LG전자 사내벤처 그로로 커뮤니티 서비스 프론트 개발, 퍼블리싱",
      "date": "유지보수 계약 (파트타임) 2023.2.28 ~ 2023.04.30\n1차 재계약 (풀타임) 2022.05.01 ~ 2023.08.31",
      "summary": [
        "React",
        "Recoil",
        "React-query",
        "Next",
        "반응형 웹",
        "퍼블리싱",
        "기획 일부 참여",
        "재택근무"
      ],
      "text": "첫 서비스 추가 개발 이후 팀 내에 퍼블리싱 가능한 인력이 없고, 리액트를 오랜 기간 사용해 보신 분이 없어서 추후 유지 보수를 계속해 주시길 요청하셨습니다. 이후 핀터레스트 형태의 간략한 메시지 또는 이미지를 무한 스크롤 형태로 출력하고 주제별로 쉽게 보기 위한 커뮤니티 서비스를 원하셔서 기획에 함께 참여하여 기술적 가이드를 드리고 lfmall 프로젝트 이후에는 본 계약을 체결하여 진행하였습니다."
    },
    {
      "id": 11,
      "keyword": "# Project",
      "title": "LFmall LNB 브랜드 탭 리뉴얼, 나의 패션 크루 서비스 신설, 베스트 탭 신설 프론트 개발",
      "date": "2022.11.07 ~ 2023.04.31",
      "summary": ["React", "Recoil", "React-query", "모바일 웹"],
      "text": "LFmall 쇼핑몰의 일부 화면의 기능 및 디자인 리뉴얼과 일부 화면 신설하는 프로젝트를 진행하였고, 기존 기간에 추가로 2달 정도 더 연장하여 sns 형식의 패션 유형화 서비스 개발하는 프로젝트를 추가로 진행했습니다. 기획이 상시 변경되고 퍼블리싱과 백엔드 작업도 많이 진행되어 있지 않고 작업 기간도 길지 않아 미리 프론트 작업을 예상하여 작업하는 경우도 많았습니다."
    },
    {
      "id": 10,
      "keyword": "# Project",
      "title": "LG전자 사내벤처 그로로 메이커 서비스 프론트 개발, 퍼블리싱",
      "date": "2022.06.20 ~ 2022.10.31 \n유지보수 추가 계약 (파트타임) 2022.11.01 ~ 2023.02.28",
      "summary": [
        "React",
        "Redux",
        "Next",
        "TinyMCE",
        "Pintura",
        "Apexcharts",
        "반응형 웹",
        "퍼블리싱",
        "디자인 가이드",
        "기획 일부 참여",
        "부분 재택근무"
      ],
      "text": "기존 그로로 서비스와 연동되는 포스트 작성, 게시 등의 서비스입니다. \n초기 계획은 몇 개월 먼저 투입된 사외 퍼블리싱팀에서 전달받은 html을 기반으로 작업할 예정이었으나, 해당 작업물과 기간에 문제가 많았고 디자인/기획 팀에서도 경험이 많지는 않아 어려움이 많았습니다. \n 결국 새 퍼블리싱팀을 구해야 했고 작업물을 받는 데까지는 최소 2달이 걸린다고 하여 직접 퍼블리싱까지 하게 되었습니다. \n퍼블리싱 가능한 인원이 혼자라서 업무량이 많긴 했지만 원래 계획된 기간에서 2주 정도만 연장하여 개발까지 완료했습니다. \nSSR 환경과 다양한 텍스트 에디터, 이미지 편집 에디터를 깊게 다뤄본 것이 좋은 경험이 되었습니다. 10월말일까지 개발계약을 잘 마무리하고, 2월말까지 주말이나 평일 퇴근 후 유지보수를 돕는 계약도 진행하였습니다."
    },
    {
      "id": 9,
      "keyword": "# Project",
      "title": "포스코ICT Smart 구매 프로젝트 프론트 개발",
      "date": "2022.02.16 ~ 2022.06.17",
      "summary": [
        "React",
        "MobX",
        "AG-grid",
        "부분 반응형 웹",
        "Iframe간 통신",
        "신규 공통 컴포넌트 기획/개발",
        "퍼블리싱 가이드"
      ],
      "text": "포스코ICT의 웹 ERP 시스템을 React 환경으로 재구축 및 개편하는 프로젝트에 참여하였습니다. \n 기반이 되는 공통 컴포넌트가 사내 타 프로젝트에서 3~4년 전 node 버전으로 개발되어 다른 모든 라이브러리를 해당 버전에 맞춰서 작업하였습니다. \nReact Hook을 사용할 수 없는 환경이라 class 형태로 과거 방식의 개발을 경험해 볼 수 있었습니다. 먼저 개발 중인 팀원들 대부분이 React 개발이 처음인데다, 사외에 있는 퍼블리싱 팀도 React 같은 프레임워크에 맞춰 작업하는 것이 처음이라 가이드에 난항이 많았습니다."
    },
    {
      "id": 8,
      "keyword": "# Project",
      "title": "삼성SDS 차세대 Knox Portal 개발 (현장관리자)",
      "date": "2021.05.26 ~ 2021.12.31",
      "summary": [
        "React",
        "Context 사용",
        "Rollup",
        "Storybook",
        "부분 반응형 웹",
        "화면 내 Iframe 다수",
        "공통 컴포넌트 및 모듈 기획/개발",
        "개발팀 현장관리자 (PL)"
      ],
      "text": "삼성의 전 직원이 사용하는 기존 Knox Portal을 React를 사용하여 새롭게 개발하는 프로젝트입니다.\nMaterial-UI를 지향하는 Knox 전용 공통 컴포넌트들과 해당 컴포넌트로 이루어진 일부 모듈들을 개발하였습니다.\n기존의 삼성SDS의 Knox Portal 팀은 React 및 컴포넌트 형식의 디자인/개발 경험이 전혀 없었기 때문에 UX와 개발 방향을 제안드리며 작업하였습니다."
    },
    {
      "id": 7,
      "keyword": "# Project",
      "title": "개인 포트폴리오 기획, 퍼블리싱, 프론트 개발",
      "date": "2020.10 ~ 2021.04 (476시간)",
      "summary": [
        "React",
        "Redux",
        "Parcel",
        "반응형 웹",
        "GSAP",
        "Video to canvas 구현",
        "Typescript 적용 (22.1.11 ~ 22.1.27)"
      ],
      "text": "React 개발 경험이 많지 않아 좀 더 익숙해질 겸 지루하지 않고,\n다양한 표현방식을 가진 포트폴리오를 만들고 싶었습니다.\n일부 이미지 파일과 SVG 아이콘 등을 제외하고 content 및 사이트 구성, UX 기획, 디자인, 개발 등 직접 작업하였습니다.\n개발 기간 자체는 꽤 길지만 가사 및 육아를 병행하였고, 실제 개발 시간은 476시간으로 주 40시간 근로 기준으로 59.5일 (약 두달 보름)입니다."
    },
    {
      "id": 6,
      "keyword": "# Project",
      "title": "커스터디 서비스 기획, 퍼블리싱, 프론트 개발",
      "date": "2019.12 ~ 2020.04",
      "summary": [
        "React",
        "MobX",
        "Webpack",
        "Material-UI",
        "반응형 웹",
        "기간별 자산 변동량 Chart 개발",
        "보유 자산 비율 Chart 개발",
        "사이트 번역 및 개발",
        "이메일 폼 퍼블리싱"
      ],
      "text": "프로젝트 초기에는 디자이너 없이 Material-UI 기능과 디자인을 그대로 사용하여 기본적인 구조가 개발되었으나, 제가 계약된 당시에는 디자이너가 투입되어 해당 서비스에 디자인을 적용해야 했습니다.\n기존에 작성된 코드는 이미 대부분 Material-UI에서 제공되는 컴포넌트들로 구성되어 있었기 때문에 해당 컴포넌트들에서 디자인 요소를 제거한 뒤 새로 스타일을 입히거나 오버라이드 하여 작업하였습니다."
    },
    {
      "id": 5,
      "keyword": "# Project",
      "title": "암호화폐 웹 레그/전산관리 솔루션 기획, 퍼블리싱, 프론트 개발",
      "date": "2019.06 ~ 2019.10",
      "summary": [
        "해당 기간에 동일 서비스 3개 개발",
        "Webpack",
        "JQuery",
        "반응형 웹",
        "회원 조직도 출력 및 검색 기능 개발",
        "기간 지정 달력 기능 도입",
        "세밀하고 다양한 계산식 개발"
      ],
      "text": "클라이언트의 요청으로 회원 및 보유 암호화폐를 관리하는 웹 사이트를 개발하였습니다.\n회원 간의 조직도를 웹에서 표현하기 위해 OrgChart 기능을 도입하였고 조직도가 상당한 분량의 데이터를 표현해야 하므로 Zoom In/Out, Drag, 검색ㅈ 기능을 추가로 개발했습니다."
    },
    {
      "id": 4,
      "keyword": "# Project",
      "title": "회사 소개 홈페이지 및 데모 웹 지갑 기획",
      "date": "2019.06 ~ 2019.10",
      "summary": ["사이트 구조 및 UX 기획", "사이트 내부 컨텐츠 기획"],
      "text": "설립되고 몇 년이나 흘렀지만 회사를 소개하는 웹 사이트가 없었습니다.\n대표와 상의하여 content 및 사이트 구성, UX 기획 등을 하였습니다.\n당시 솔루션 개발을 동시에 하고 있었으므로 개발 자체는 직접 하지 않았습니다."
    },
    {
      "id": 3,
      "keyword": "# Project",
      "title": "암호화폐 ICO/IEO 사이트 기획, 퍼블리싱, 프론트 개발",
      "date": "2019.03 ~ 2019.06",
      "summary": [
        "ICO 사이트 2개 / IEO 사이트 2개",
        "JQuery",
        "Webpack",
        "GSAP",
        "반응형 웹",
        "타이머 및 프로그레스 바 기능 도입"
      ],
      "text": "클라이언트의 요청으로 ICO(Initial Coin Offering), IEO(Initial Exchange Offering) 기능의 웹 사이트를 개발하였습니다.\n매끄러운 Parallax 효과를 위해 GSAP를 사용하였고 작은 기기에서도 테이블 형태의 정보를 쉽게 편하게 확인하기 위해 이중 스크롤 레이아웃으로 개발한 특이점이 있습니다."
    },
    {
      "id": 2,
      "keyword": "# Project",
      "title": "암호화폐 웹 거래소/지갑 기획, 퍼블리싱, 프론트 개발",
      "date": "2017 ~ 2019.03",
      "summary": [
        "웹 거래소 9개 / 웹 지갑 6개",
        "JQuery",
        "Google reCaptcha",
        "반응형 웹",
        "암호화폐 시간/기간별 시세 Chart 개발",
        "사이트 번역 및 개발",
        "웹 QR Code Generator 개발",
        "웹 QR Code Scanner 개발",
        "세밀하고 다양한 계산식 개발"
      ],
      "text": "오픈소스 기반의 암호화폐 웹 지갑과 거래소 소스를 지속적으로 개선하여 다양한 기업에 판매하였습니다.\n처음에는 프랑스어 주석만 가득하고 기본 거래 기능만 있던 소스를 차트와 새로운 코인 연동, 성능 개선 등을 반복하고, 고객 요청에 따라 다양한 추가 기능을 개발하다 보니 해당 기능들을 옵션 형태로 넣고 빼며 최종적으로는 테마 형태로 디자인들을 추가하여 다양한 솔루션형태로 판매하였습니다. 또한, 사용자들이 소수점 단위의 구입, 구축, 전송 등의 다양한 계산을 해야 하므로 프론트에서 입력값에 따른 계산식을 개발하여 입력값에 즉시 출력되는 입력 제한 또는 계산 결과, 가이드 멘트 등을 개발했습니다. 당시 모든 타 거래소는 PC와 휴대기기 버전을 따로 개발했으나 완전 반응형으로 작업해온 특이점이 있습니다."
    },
    {
      "id": 1,
      "keyword": "# Subcontract",
      "title": "각종 기업의 소개/운영 사이트 퍼블리싱/프론트 개발",
      "date": "2016.02 ~ 2017.12",
      "summary": [
        "쇼핑몰 웹 사이트 3개 (Firstmall, Gnuboard, 일반기업 솔루션)",
        "이사센터 웹 사이트 (퍼블리싱)",
        "애완동물 관련 업체 웹 사이트 (Wordpress)",
        "헬스장 통합 솔루션 웹 사이트 (Wordpress)",
        "데이팅 솔루션 웹 사이트 (Wordpress)",
        "일식 요리학원 웹 사이트 (XEboard, Gnuboard)",
        "종이액자기업 웹 사이트 (Wordpress)",
        "제약, 바이오 화학분야 기업 웹 사이트 (Wordpress)",
        "플라스마 기술 기업 웹 사이트. (Wordpress)",
        "흥신소 소개 웹 사이트 (Wordpress)",
        "JQuery 개발",
        "반응형 웹"
      ],
      "text": "독학으로 퍼블리싱을 공부하며 지인들과 함께 팀을 꾸려 다양한 외주작업을 했습니다.\n팀에 Backend 개발자가 따로 없었던 기간이라 WordPress, Gnuboard 등 다양한 프레임워크를 통해 업무를 진행하였습니다."
    }
  ]
}
