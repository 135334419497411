{
  "lib": [
    {
      "number": 1,
      "id": "react",
      "name": "React",
      "workmanship": 4,
      "summary": "처음부터 Hook 기반의 개발을 주로 해왔고, 오히려 이후에 경험한 프로젝트에서 class 기반 개발을 해볼 기회가 있었습니다. 현재는 React와 관련된 이슈로 인해 작업에 딜레이 발생할 일은 없는 수준은 되는 것 같습니다."
    },
    {
      "number": 2,
      "id": "redux",
      "name": "Redux",
      "workmanship": 3,
      "summary": "가장 베이스 형태의 작성 방식으로 현재 포트폴리오에서 사용했습니다.\n 이후 다양한 프로젝트에서 보일러플레이트 없이 더 다양하고 간편한 상태관리 라이브러리 위주로 사용하게되어 리덕스의 미들웨어 관련부분은 다뤄보지 못 했습니다."
    },
    {
      "number": 3,
      "id": "webpack",
      "name": "Webpack",
      "workmanship": 3,
      "summary": "React를 처음 학습할 당시 CRA가 아닌 webpack으로 개발 환경을 직접 구축해가며 공부했습니다.\n 마지막으로 다룬 경험은 webpack5로 개인 프로젝트에서 설정해 본 일이 있습니다만, 현재까지 빌더의 환경이나 세팅이 필요한 프로젝트는 못 만나봤습니다."
    },
    {
      "number": 4,
      "id": "parcel",
      "name": "Parcel",
      "workmanship": 4,
      "summary": "지금의 포트폴리오 작업을 하면서 사용해 보았습니다.\n 초기 설정은 크게 손댈 일이 없었지만, 특수한 케이스에서 이슈를 처리하기 위한 사용자 정보가 당시에는 적은 게 아쉬웠습니다.\n 그래도 자체 러닝 커브가 적고 빌드 성능이 뛰어나서 좋았습니다."
    },
    {
      "number": 5,
      "id": "sass",
      "name": "SASS(SCSS)",
      "workmanship": 5,
      "summary": "더 이상은 CSS를 직접 작업하는 일은 없을 것 같습니다.\n 꼭 필요하다면 SCSS로 작성 후 CSS를 빌드 합니다.\n 그만큼 스타일 작업에서는 필수적인 스킬입니다.\n 상황에 따라 mixin 및 변수 등을 적극 활용하여 최적의 속도로 작업할 수 있습니다."
    },
    {
      "number": 6,
      "id": "gsap",
      "name": "GSAP",
      "workmanship": 4,
      "summary": "스타일로 작업한 동적 효과보다 훨씬 성능이 뛰어난 라이브러리입니다.\n제공되는 무료 기능 외에 다양하게 응용이 가능합니다."
    },
    {
      "number": 7,
      "id": "i18next",
      "name": "I18next",
      "workmanship": 5,
      "summary": "다양한 환경에서 다양한 언어를 적용해보았습니다."
    },
    {
      "number": 8,
      "id": "jquery",
      "name": "Jquery",
      "workmanship": 4,
      "summary": "javascript보다 먼저 익혔고, 상당 기간 사용했었습니다만,\n 주로 사용하는 React 프레임워크에서 사용할 기회도, 필요도 없기에 많이 잊게되었습니다."
    },
    {
      "number": 9,
      "id": "bootstrap",
      "name": "Bootstrap",
      "workmanship": 5,
      "summary": "반응형 사이트를 처음 접하고 개발할 당시 자주 사용하였던 프레임워크입니다, 현재까지도 BreakPoint의 베이스로 참고하고, Grid 커스텀에도 참고합니다."
    },
    {
      "number": 10,
      "id": "materialui",
      "name": "Material-UI",
      "workmanship": 4,
      "summary": "특정 프로젝트에서 해당 프레임워크를 기반으로 개발 요청이 들어와 사용했습니다."
    },
    {
      "number": 11,
      "id": "axios",
      "name": "Axios",
      "workmanship": 4,
      "summary": "편리하고 익숙합니다."
    },
    {
      "number": 12,
      "id": "storoybook",
      "name": "Storybook",
      "workmanship": 4,
      "summary": "기본적인 document 작성 외에 다양한 방식이나 애드온까지는 사용할 기회가 없었지만, 이후 다루는 일이 생겨도 어려움은 없을 것 같습니다."
    },
    {
      "number": 13,
      "id": "mobx",
      "name": "MobX",
      "workmanship": 3,
      "summary": "프로젝트 환경에 특이점이 있어서, 데코레이터를 사용하는 예전 버전으로 사용한 경험이 있습니다."
    },
    {
      "number": 14,
      "id": "aggrid",
      "name": "AG-Grid",
      "workmanship": 4,
      "summary": "항상 상황에 따라 테이블을 직접 개발해왔는데, 사용해 보니 확실히 시간을 많이 아낄 수 있었습니다."
    },
    {
      "number": 15,
      "id": "nextjs",
      "name": "Next.js",
      "workmanship": 3,
      "summary": "꽤 오랜시간 다루었지만 최근의 버전을 다룰 기회는 아직 없었습니다."
    },
    {
      "number": 16,
      "id": "recoil",
      "name": "Recoil",
      "workmanship": 4,
      "summary": "최근에 사용해본 상태관리 라이브러리 중에 러닝커브도 가장 적고, 직관적이서 좋았습니다."
    },
    {
      "number": 17,
      "id": "reactquery",
      "name": "ReactQuery",
      "workmanship": 3,
      "summary": "이제 꽤 다양하게 사용해본 것 같습니다. 원하는데로 구현하는데 문제는 없어보입니다."
    }
  ]
}
